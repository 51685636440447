import React from 'react';
import cn from 'classnames';
import './styles.scss';
import {LibraryFilter} from '../../../components/library/LibraryEnums';

interface ILocationProps {
  title: string;
  id: number;
  serialNumber: string | undefined;
  checked?: boolean;
  onToggle: (serialNumber: string | undefined, id: number, checked: boolean, typeFilter: LibraryFilter) => void;
}

export const Location = ({
  title,
  id,
  serialNumber,
  checked = false,
  onToggle
}: ILocationProps): JSX.Element => {
  const handleToggle = () => {
    onToggle(serialNumber, id, !checked, LibraryFilter.Locations);
  };

  return <div
    className={cn('location', {'location--checked': checked})}
    role="presentation"
    onClick={handleToggle}><div className="location__text">{title}</div>
  </div>;
};
