import React, {useEffect, useState} from 'react';

import './styles.scss';
import {IJournalStatisticValue, IJournalStatisticValues} from '../../../redux/journal-stats/interfaces';

interface IJournalStatValues {
  values: IJournalStatisticValue[]
  name: string
}

export const JournalStatSymptomValues: React.FunctionComponent<IJournalStatValues> = ({values, name}: IJournalStatValues) => {
  const [groupValues, setGroupsValues] = useState<{ [key: string]: IJournalStatisticValue[] }>({});
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    if (values) {
      setGroups(values.map(v => v.question).filter((elem, index, self) => {
        return index === self.indexOf(elem);
      }));

      const result = values.reduce((group: { [key: string]: IJournalStatisticValue[] }, value: IJournalStatisticValue) => {
        return {...group, [value.question]: [...group[value.question] || [], value]};
      }, {});

      setGroupsValues(result);
    }
  }, [values]);

  return <div className="stats__values-group-symptoms">
    <div className="stats__values-group-name">{name}</div>
    {groups && groups.map((group, i) => {
      return <div key={i} className="stats__values-group-questions">
        <div className="stats__values-group-questions-text">{group}</div>
        <div>
          {groupValues && groupValues[group].map((v, vi) => {
            return <div key={vi} className="stats__value">
              <div className="stats__value-text">
                {v.answer}
              </div>
              <div className="stats__value-number">
                {v.value}
              </div>
            </div>;
          })}
        </div>
      </div>;
    })
    }
  </div>;
};
