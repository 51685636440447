import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import cn from 'classnames';

import {Navigation, LanguageMenu} from './Navigation/Navigation';

import {startLoginAction} from 'redux/auth/authAsyncActions';
import {IStore} from 'redux/interface';
import {loadTranslation} from 'redux/translation/translationAsyncActions';

import Logo from 'assets/image/header/jun_logo.png';
import AdditionalLogo from 'assets/image/header/logo-nursing-academics.svg';
import LoginImg from 'assets/image/home-page/login.svg';
import Menu from 'assets/image/header/menu.svg';
import CloseMenu from 'assets/image/header/close.svg';
import AppStore from 'assets/image/social-mobile/AppStore.svg';
import GoogleApp from 'assets/image/connect-line/downloadViaGooglePlay.svg';
import UserIcon from 'assets/image/user-settings/userDefaultAvatar.png';

import {proUser} from 'utils/constants/constants';
import {appStoreUrl, desktopWidth, googlePlayUrl} from 'utils/constants/constants';
import {useResize} from 'utils/hooks/useResize';
import setIsBodyScrolling from 'utils/helpers/setIsBodyScrolling';
import {RoutesUrl, routeTranslationKeys} from 'utils/constants/routeConstants';
import {useLoadFile} from 'utils/hooks/useLoadFile';

import './styles.scss';

export const Header: React.FunctionComponent = () => {
  const {
    auth: {
      auth0User,
      userProfile
    },
    translation: {
      translationKeys
    }
  } = useSelector((state: IStore) => state);

  const dispatch = useDispatch();
  const {
    getFileUrlAsync
  } = useLoadFile();

  const [isOpenAuthMenu, setIsOpenAuthMenu] = useState(false);
  const [isOpenGuestMenu, setIsOpenGuestMenu] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const proFlag = auth0User?.['https://gritx.org/roles']?.includes(proUser);

  const screenWidth = useResize();

  const node = useRef<HTMLDivElement>(null);

  const getFileUrl = async (avatarId: number) => {
    const url = await getFileUrlAsync(avatarId);

    setAvatarUrl(url);
  };

  useEffect(() => {
    if (screenWidth >= desktopWidth && (isOpenAuthMenu || isOpenGuestMenu)) {
      setIsBodyScrolling(true);
      setIsOpenAuthMenu(false);
      setIsOpenGuestMenu(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (userProfile) {
      if (userProfile.userAvatarId) {
        getFileUrl(userProfile.userAvatarId);
      } else {
        setAvatarUrl(UserIcon);
      }
    }
  }, [userProfile?.userAvatarId]);

  const escapeListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpenAuthMenu(false);
      setIsOpenGuestMenu(false);
    }
  }, []);

  const handleClick = useCallback((event: MouseEvent): void => {
    const element = event.target as HTMLElement;

    if (node.current && node.current.contains(element)) {
      return;
    }
    setIsOpenAuthMenu(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keyup', escapeListener);
    };
  }, []);

  async function handleSignIn() {
    if (isOpenGuestMenu) {
      setIsOpenGuestMenu(false);
    }
    dispatch(startLoginAction());
  }

  function handleSignOut() {
    if (isOpenAuthMenu) {
      setIsOpenAuthMenu(false);
    }
  }

  function handleBurgerButton() {
    setIsBodyScrolling(isOpenGuestMenu);
    setIsOpenGuestMenu(!isOpenGuestMenu);
  }

  function handleMenuAuthButton() {
    setIsOpenAuthMenu(!isOpenAuthMenu);
  }

  function handleScrollTop() {
    window.scrollTo(0, 0);
  }

  function handleCloseAuthMenu() {
    setIsOpenAuthMenu(false);
  }

  function handleClickSettings() {
    if (!translationKeys.includes(routeTranslationKeys[RoutesUrl.Settings])) {
      dispatch(loadTranslation(routeTranslationKeys[RoutesUrl.Settings]));
    }
    setIsOpenAuthMenu(false);
  }

  function handleClickAbout() {
    if (!translationKeys.includes(routeTranslationKeys[RoutesUrl.About])) {
      dispatch(loadTranslation(routeTranslationKeys[RoutesUrl.About]));
    }
    setIsOpenAuthMenu(false);
  }

  function showUserInitials() {
    return (
      (userProfile && userProfile.firstName && userProfile.lastName)
        ? `${userProfile.firstName.substr(0, 1)}${userProfile.lastName.substr(0, 1)}`
        : auth0User?.nickname.substr(0, 2)
    );
  }

  const ButtonSignIn = () => {
    return (
      <div className="header-button-wrapper">
        {auth0User === null ? (
          <button
            className="header-button default-signIn-btn"
            onClick={handleSignIn}
          >
            {/* <img className="signIn-btn-image" src={LoginImg} alt="" role="presentation"/>*/}
            <FormattedMessage
              id={'gritx.common.header.signIn'}
              defaultMessage={'Sign In'}
            />
          </button>
        ) : (
          <button
            className="header-button default-signIn-btn"
            onClick={handleSignOut}
          >
            {avatarUrl && <img
              className="signIn-btn-image"
              src={avatarUrl}
              alt=""
              role="presentation"
            />}
            <FormattedMessage
              id={'gritx.common.header.signOut'}
              defaultMessage={'Sign Out'}
            />
          </button>
        )}
      </div>
    );
  };

  const ButtonAuthUser = () => {
    return (
      <div className="header-button-wrapper">
        {auth0User !== null ? (
          <button
            className="default-auth-user"
            onClick={handleMenuAuthButton}
          >
            {avatarUrl && <img
              className="signIn-btn-image"
              src={avatarUrl}
              alt=""
              role="presentation"
            />}
            <p className="default-auth-user__name">
              {showUserInitials()}
            </p>
          </button>
        ) : null}
      </div>
    );
  };

  const MenuAuthUser = () => {
    return (
      <ul className="menu-auth__items">
        <li className="menu-auth__item">
          <NavLink
            className="menu-auth__btn"
            to={RoutesUrl.Settings}
            onClick={handleClickSettings}
          >
            <span className="menu-auth__name">
              {showUserInitials()}
            </span>
            <p className="menu-auth__text">
              <FormattedMessage
                id={'gritx.common.header.settings-description'}
                defaultMessage={'Name, contacts, avatar, language'}
              />
            </p>
          </NavLink>
        </li>
        <li className="menu-auth__item">
          <NavLink
            className="menu-auth__btn"
            to={RoutesUrl.About}
            onClick={handleClickAbout}
          >
            <FormattedMessage
              id={'gritx.common.navigation.About'}
              defaultMessage={'About'}
            />
          </NavLink>
        </li>
        <li className="menu-auth__item">
          <NavLink
            to="/logout"
            className="menu-auth__btn"
            onClick={handleSignOut}
          >
            <FormattedMessage
              id={'gritx.common.header.logout'}
              defaultMessage={'Logout'}
            />
          </NavLink>
        </li>
      </ul>
    );
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__wrapper">
          <div className="header__left">
            <div className="header__logo-wrapper">
              {auth0User === null
                ? <></>
                : (
                  <button className="header__logo" onClick={handleScrollTop}>
                    <img className="header__logo-image" src={Logo} alt="Logo"/>
                  </button>
                )}
              {auth0User === null
                ? <></>
                : <div className="header__additional-logo"><img className="header__additional-logo-image" src={AdditionalLogo} alt="AdditionalLogo"/></div>
              }
            </div>
            {auth0User === null
              ? <></>
              : (
                <Navigation
                  className="nav__desktop"
                  proUser={proFlag}
                  onOpenMenu={handleCloseAuthMenu}
                />
              )}
          </div>
          <div className="header__right">
            {auth0User === null ? <ButtonSignIn/> : <ButtonAuthUser/>}

            {auth0User === null ? (
              <button className="burger__item" onClick={handleBurgerButton}>
                {isOpenGuestMenu ? (
                  <img
                    className="burger__image"
                    src={CloseMenu}
                    alt="Close Menu"
                  />
                ) : (
                  <img className="burger__image" src={Menu} alt="Menu"/>
                )}
              </button>
            ) : null}
          </div>
        </div>

        {auth0User === null ? (
          <div
            className={cn('header__menu', {
              'header__menu--hidden': !isOpenGuestMenu
            })}
          >
            <div className="menu">
              <div className="menu__content">
                {/* <div className="menu__store">*/}
                {/*  <a*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer"*/}
                {/*    href={appStoreUrl}*/}
                {/*    className="menu__store-link"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      className="menu__store-img"*/}
                {/*      src={AppStore}*/}
                {/*      alt=""*/}
                {/*      role="presentation"*/}
                {/*    />*/}
                {/*    <span className="visually-hidden">*/}
                {/*      <FormattedMessage*/}
                {/*        id={'gritx.common.downloadApp.appStore'}*/}
                {/*        defaultMessage={'Go to the download application in App Store'}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*  </a>*/}
                {/* </div>*/}
                {/* <p className="menu__content-button">*/}
                {/*  <a*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer"*/}
                {/*    href={intIosAccess}*/}
                {/*    className="menu__content-link"*/}
                {/*  >*/}
                {/*    International iOS Access*/}
                {/*  </a>*/}
                {/* </p>*/}
                {/* <div className="menu__store">*/}
                {/*  <a*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer"*/}
                {/*    href={googlePlayUrl}*/}
                {/*    className="menu__store-link"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      className="menu__store-img"*/}
                {/*      src={GoogleApp}*/}
                {/*      alt=""*/}
                {/*      role="presentation"*/}
                {/*    />*/}
                {/*    <span className="visually-hidden">*/}
                {/*      <FormattedMessage*/}
                {/*        id={'gritx.common.downloadApp.googlePlay'}*/}
                {/*        defaultMessage={'Go to the download application in Google Play'}*/}
                {/*      />*/}
                {/*    </span>*/}
                {/*  </a>*/}
                {/* </div>*/}
                <button className="menu__button" onClick={handleSignIn}>
                  {/* <img*/}
                  {/*  className="menu__button-image"*/}
                  {/*  src={LoginImg}*/}
                  {/*  alt=""*/}
                  {/*  role="presentation"*/}
                  {/* />*/}
                  <FormattedMessage
                    id={'gritx.common.header.signIn'}
                    defaultMessage={'Sign In'}
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div ref={node} className="menu-auth--opened">
            <div
              className={cn('menu-auth', {'menu-auth--hidden': !isOpenAuthMenu})}
            >
              <Navigation
                className="nav__mobile"
                proUser={proFlag}
                onOpenMenu={handleCloseAuthMenu}
              />
              <MenuAuthUser/>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
